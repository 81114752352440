import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import {
  FormExpander,
  Row,
  Label,
  Input,
  Button,
  Checkbox,
  Hint,
  Text,
  Divider,
  Select,
  SelectOption,
  ErrorHint,
} from '../Widgets';
import {message, Upload} from 'antd';
import {ErrProfileFormat, ErrUpgrade, errorHandler} from '../errors';
import styled from 'styled-components';
import {UPGRADE_STATUS, MONTHLY_TYPE} from '../dictionary';
import formatValidator from '../Utils/formatValidator';
import AddressField from '../Components/AddressField';
import {isMonthly} from '../Utils/UserUtil';
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined';
import MonthlyNotice, {
  metadata as monthlyNoticeMeta,
} from '../Modals/MonthlyNotice';
const appConfig = require('../data.json');

const {isNotEmpty, isNumeric} = formatValidator;

export default function RequestMonthlyPanel(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [data, _setData] = useState({
    industry_type: '文化創意／藝文相關',
    monthly_type: 'last_in_month',
  });
  const [upgrade, setUpgrade] = useState();
  const [agree, setAgree] = useState(false);
  const [validate, setValidate] = useState(false);
  const [uploadData, setUploadData] = useState();

  const editable =
    !(
      (upgrade?.state === 'pending')
      // || upgrade?.state === 'rejected'
    ) && !isMonthly(profile);

  function setData(obj) {
    _setData((pre) => ({...pre, ...obj}));
  }

  useEffect(() => {
    if (profile) {
      try {
        let parsed = JSON.parse(profile.data);
        setData(parsed);
      } catch (ex) {}
    }
  }, [profile]);

  useEffect(() => {
    (async () => {
      try {
        let upgrade = await app.actions.getUpgrade();
        if (upgrade) {
          setUpgrade(upgrade);
          let data = JSON.parse(upgrade.data);
          setData(data || {});
        }
      } catch (err) {}
    })();
  }, [profile]);

  const valid = () => {
    setValidate(true);
    if (
      !isNotEmpty(data.ent_name) ||
      !isNotEmpty(data.gui_number) ||
      !isNotEmpty(data.ent_tel) ||
      !isNotEmpty(data.ent_address) ||
      !isNotEmpty(data.ent_city) ||
      !isNotEmpty(data.ent_district)
    ) {
      throw new ErrProfileFormat('欄位不能為空');
    } else if (!isNumeric(data.gui_number) || !isNumeric(data.ent_tel)) {
      throw new ErrProfileFormat('格式錯誤');
    }
    if (!isNotEmpty(data.license_image)) {
      throw new ErrProfileFormat('請上傳營業執照文件');
    }
  };

  const submit = async () => {
    try {
      if (!agree) {
        throw new ErrProfileFormat('請先閱讀完說明');
      }
      valid();

      await app.actions.reqUpgradeUserType({
        data: JSON.stringify(data),
      });
      await app.actions.getProfile();
      message.success('已送出申請');
    } catch (err) {
      console.warn(err);
      if (err instanceof ErrProfileFormat) {
        message.error(err.message);
      } else {
        message.error('申請發生錯誤');
      }
    }
  };

  const getUploadPresignUrl = useCallback(
    async (file) => {
      app.actions.setLoading(true);
      try {
        let {token: jwtToken} = await app.actions.getJwtToken();

        let resp = await app.actions.getUploadPresignUrl(
          file,
          {acl: 'private'},
          jwtToken,
        );
        setUploadData({
          url: resp.url,
          data: resp.fields,
          expected: resp.expected,
        });
      } catch (err) {
        message.warning('無法取得上傳路徑');
        app.actions.setLoading(false);
      }
    },
    [app.actions],
  );

  const previewFile = async (key) => {
    try {
      app.actions.setLoading(true);

      if (!key) {
        throw new ErrUpgrade('找不到檔名');
      }
      let {token: jwtToken} = await app.actions.getJwtToken();
      let {url} = await app.actions.getPrivateFile(key, jwtToken);

      window.open(url, '_blank');
    } catch (err) {
      errorHandler(err);
    }
    app.actions.setLoading(false);
  };

  return (
    <div>
      <FormExpander title="月結服務說明" closable={false}>
        <Description>
          <h4>月結會員專屬服務</h4>
          <div className="content">
            <p>• 提供您每月帳單報表資料。</p>
            <p>
              • 享有約定月結還款期＝緩衝繳費期(預設為30天)，提高企業資金效益。
            </p>
            <p>• 優先獲取平台相關業務信息或資料。</p>
            <p>• 優先體驗和試用新服務。</p>
            <p>• 擁有比普通客戶更多、更方便的增值服務選擇。</p>
          </div>

          <h4>申請加入條件</h4>
          <div>
            詳細申請月結會員條件，歡迎聯繫本 {appConfig.information.name}{' '}
            網路平台客服人員，由專人客服接洽。
          </div>
          <div className="content">
            <p>• 申請開通月結帳戶前，必須先擁有本服務企業會員帳號。</p>
            <p>
              •
              月結會員僅限於學校行政單位、公司、商行、工廠及協會等具有統一編號之營利事業或財團法人。
            </p>
            <p>• 月結會員申請時填寫之相關資料使用適用於本服務之隱私權政策。</p>
            <p>
              •
              歡迎聯繫客服人員洽詢，由客服專人確認是否符合申請成為月結會員資格。
            </p>
            <p>
              •
              本公司針對申請單位進行身份識別審查，並保留審查通過與否的權利，可接受再聯繫客服人員申請。
            </p>
          </div>

          <h4>月結方式說明</h4>
          <div className="content">
            <p>• 月結客戶付款方式為當月消費、次月付款。</p>
            <p>• 月結結算日可指定日期 ( 於申請月結會員同時與客服人員協定 )：</p>
            <div
              style={{
                margin: '10px 20px',
                paddingLeft: 20,
                borderLeft: 'solid 1px #D9D8DA',
              }}>
              <div>每月1號</div>
              <div>每月5號</div>
              <div>每月15號</div>
              <div>每月20號</div>
              <div>每月25號</div>
              <div>每月最後一天</div>
            </div>
            <p>
              • 結算日提供『 月結對帳單 』及『 月結發票乙張 』向該月結會員請款。
            </p>
            <p>
              •
              客戶需於結算日後『緩衝繳費期』內繳費費用。如月結會員未於『繳費截止日』前完成繳費程序，則停止其月結下單資格，待處理完成後始得恢復。
            </p>
          </div>

          <h4>申請程序</h4>
          <div className="content">
            <p>(1) 聯繫客服：詳細申請月結會員條件，歡迎聯繫客服人員洽詢。</p>
            <p>
              (2)
              評估資格：由客服專人確認符合申請成為月結會員資格，並談妥月結服務條件後線上申請。
            </p>
            <p>
              (3) 線上申請：點選『申請成為企業月結會員』 填妥『 月結服務申請表
              』申請資料送審。
            </p>
            <p>
              (4) 客服收到申請將幫您審核資格 ;
              通過後會將以Email及電話通知該企業會員。
            </p>

            <p>
              •
              本公司針對申請單位進行身份識別審查並保留審查通過與否的權利，可接受再聯繫客服人員申請。
            </p>
            <p>• 客戶需待審查核可通過後，始能以月結客戶身份於平台消費。</p>
          </div>

          <h4>申請準備資料</h4>
          <div>
            若您符合以上申請條件，請填寫『企業月結會員申請』資料，並備妥以下資料檔案掃描檔或影本：
          </div>
          <div className="content">
            <p>1. 勾選同意下方月結服務說明，並填寫『 月結會員申請 』資料。</p>
            <p>
              2.
              公司具有營業登記、商業登記、稅籍登記、財團法人及社團法人的登記(以上檔案擇一)，需持有具法律效力登記證明的營業執照。
            </p>
            <p>3. 提供公司行號支存帳號資掃描檔或影本。</p>
            <p>4. 資料送審後，我們將會盡快審核您提出的申請。</p>
          </div>

          <h4>申請注意事項</h4>
          <div className="content">
            <p>
              本公司針對申請單位進行身份識別審查並保留審查通過與否的權利，可接受再聯繫客服人員申請。
            </p>
            <p>客戶需待審查核可通過後，始能以月結客戶身份於平台消費。</p>
            <p>
              本公司保留月結企業會員的適用資格，如有惡意或不友善的消費行為，本公司將有權利停權。
            </p>
          </div>

          <h4>如何變更月結帳戶資料?</h4>
          <div className="content">
            <p>
              如您有財務資料或公司基本資料異動需求，請寄送申請變更月結會員資料寄送電子郵件：
              {appConfig.information.email}，
            </p>
            <p>
              或請於平台服務時間來電客服專線：{appConfig.information.tel}
              ，由專人為您服務修改。
            </p>
          </div>
        </Description>
      </FormExpander>
      <FormExpander title="月結服務申請表" closable={false}>
        {upgrade && (
          <Row>
            <Label flex="0 0 70px">申請狀態</Label>
            <Text
              size="sm"
              color={
                {
                  rejected: appConfig.colors.error,
                  accepted: appConfig.colors.success,
                }[upgrade.state]
              }>
              {UPGRADE_STATUS[upgrade.state]}
            </Text>
          </Row>
        )}
        {upgrade?.state === 'rejected' && (
          <Row>
            <Label flex="0 0 70px">拒絕原因</Label>
            <Text size="sm" color={appConfig.colors.error}>
              {upgrade.reason}
            </Text>
          </Row>
        )}
        <Row>
          <Label>會員名稱</Label>
          <Input
            disabled={!editable}
            value={data.contact_name}
            placeholder="會員名稱"
            onChange={(e) => setData({contact_name: e.target.value})}
          />
          <Hint>＊企業會員之名稱即公司聯絡人名稱</Hint>
        </Row>
        <Row>
          <Label>公司抬頭</Label>
          <Input
            disabled={!editable}
            value={data.ent_name}
            placeholder="公司抬頭"
            onChange={(e) => setData({ent_name: e.target.value})}
          />
          {validate && !isNotEmpty(data.ent_name) && (
            <ErrorHint>公司抬頭不能為空</ErrorHint>
          )}
        </Row>
        <Row>
          <Label>公司統編</Label>
          <Input
            disabled={!editable}
            value={data.gui_number}
            placeholder="公司統編"
            onChange={(e) => setData({gui_number: e.target.value})}
          />
          {validate && !isNotEmpty(data.gui_number) && (
            <ErrorHint>統一編號不能為空</ErrorHint>
          )}
        </Row>
        <Row>
          <Label>月結結算日</Label>
          <Select
            disabled={!editable}
            value={data.monthly_type}
            onChange={(value) => setData({monthly_type: value})}>
            {Object.keys(MONTHLY_TYPE).map((key, idx) => (
              <Select.Option key={idx} value={key}>
                {MONTHLY_TYPE[key]}
              </Select.Option>
            ))}
          </Select>
          <Hint>＊請擇一日期作為您的會員結算日</Hint>
        </Row>
        {/* <Row>
          <Label>電子信箱</Label>
          <Input
            disabled={!editable}
            value={data.email}
            placeholder="電子信箱"
            onChange={(e) => setData({email: e.target.value})}
          />
        </Row> */}
        <Row>
          <Label>產業類型</Label>
          <Select
            disabled={!editable}
            value={data.industry_type}
            onChange={(value) => setData({industry_type: value})}>
            <SelectOption value="文化創意／藝文相關">
              文化創意／藝文相關
            </SelectOption>
            <SelectOption value="印刷同業">印刷同業</SelectOption>
            <SelectOption value="廣告行銷">廣告行銷</SelectOption>
            <SelectOption value="設計公司">設計公司</SelectOption>
            <SelectOption value="個人工作室／SOHO">
              個人工作室／SOHO
            </SelectOption>
            <SelectOption value="其他">其他</SelectOption>
          </Select>
        </Row>
        <Row>
          <Label>公司電話</Label>
          <Input
            disabled={!editable}
            value={data.ent_tel}
            placeholder="公司電話"
            onChange={(e) => setData({ent_tel: e.target.value})}
          />
          <Input
            disabled={!editable}
            placeholder="分機"
            type="short"
            value={data.tel_ext || ''}
            onChange={(e) => setData({tel_ext: e.target.value})}
          />

          {validate && !isNotEmpty(data.ent_tel) ? (
            <ErrorHint>公司電話不能為空</ErrorHint>
          ) : validate && !isNumeric(data.ent_tel) ? (
            <ErrorHint>請確認公司電話格式</ErrorHint>
          ) : null}
        </Row>
        <Row>
          <Label>傳真號碼</Label>
          <Input
            disabled={!editable}
            value={data.fax}
            placeholder="傳真號碼"
            onChange={(e) => setData({fax: e.target.value})}
          />
        </Row>
        <Row>
          <Label>公司地址</Label>
          <AddressField
            disabled={!editable}
            zip_code={data.ent_zip_code}
            city={data.ent_city}
            district={data.ent_district}
            address={data.ent_address}
            onChange={(obj) =>
              setData({
                ...(obj.zip_code !== undefined && {ent_zip_code: obj.zip_code}),
                ...(obj.city !== undefined && {ent_city: obj.city}),
                ...(obj.district !== undefined && {ent_district: obj.district}),
                ...(obj.address !== undefined && {ent_address: obj.address}),
              })
            }
          />
          {/* <Input
            disabled={!editable}
            value={data.ent_address}
            placeholder="公司地址"
            onChange={(e) => setData({ent_address: e.target.value})}
          /> */}
          {validate && !isNotEmpty(data.ent_address) && (
            <ErrorHint>公司地址不能為空</ErrorHint>
          )}
          {validate &&
            (!isNotEmpty(data.ent_city) || !isNotEmpty(data.ent_district)) && (
              <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
            )}
        </Row>
        <Row>
          <Label>上傳資料</Label>
          <div>
            <Row style={{margin: 0}}>
              <Upload
                // accept="image/*"
                disabled={!editable}
                action={uploadData?.url}
                data={uploadData?.data}
                beforeUpload={getUploadPresignUrl}
                showUploadList={false}
                onChange={async (info) => {
                  if (info.file.status === 'uploading') {
                    // do progressing
                    return;
                  }
                  if (info.file.status === 'done') {
                    app.actions.setLoading(false);
                    setData({license_image: uploadData?.data.key});
                    return;
                  }
                }}>
                <div
                  style={{
                    backgroundColor: '#eee',
                    margin: 5,
                    padding: '4px 11px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <CloudUploadOutlined
                    style={{color: '#909090', fontSize: 25, marginRight: 7}}
                  />
                  <div style={{color: '#909090'}}>上傳相關營業執照文件</div>
                </div>
              </Upload>

              {validate && !isNotEmpty(data.license_image) && (
                <ErrorHint>請上傳營業執照文件</ErrorHint>
              )}
            </Row>
            {data.license_image && (
              <Row>
                <div
                  style={{
                    color: appConfig.colors.text,
                    fontSize: 12,
                    marginLeft: 5,
                    textDecoration: 'baseline',
                    cursor: 'pointer',
                  }}
                  onClick={() => previewFile(data.license_image)}>
                  {data.license_image}
                </div>
              </Row>
            )}

            <Hint style={{marginLeft: 5}}>
              ＊請上傳具有營業登記/商業登記/稅籍登記/財團法人/社團法人，之登記證明的營業執照文件（以上文件請擇一即可）
            </Hint>
          </div>
        </Row>
        {/* <Row>
            <Label>發票形式</Label>
            <Select
              disabled={!editable}
              value={data.receipt_type}
              onChange={value => setData({ receipt_type: value)}
            >}
              <SelectOption value="二聯">二聯</SelectOption>
              <SelectOption value="三聯">三聯</SelectOption>
            </Select>
          </Row>
          <Row>
            <Label>公司負責人 手機 / 電話</Label>
            <Input
              disabled={!editable}
              value={data.principal_tel}
              onChange={e =>
                setData({ principal_tel: e.target.value})
              }
            />
          </Row>
          <Row>
            <Label>聯絡人 手機 / 電話</Label>
            <Input
              disabled={!editable}
              value={data.contact_tel}
              onChange={e => setData({ contact_tel: e.target.value})}
            />
          </Row> */}
        <Divider />
        <Row wrap="nowrap" style={{alignItems: 'flex-start'}}>
          <Checkbox
            style={{marginRight: 10}}
            defaultChecked={false}
            onChange={(e) => setAgree(e.target.checked)}
          />
          <div style={{marginLeft: 10}}>
            <Text size="sm">
              我已詳細閱讀『 月結會員交易須知 』，且同意遵守『 常見問題 {'>'}{' '}
              會員須知 {'>'} 月結會員服務
              』相關月結會員服務說明，並申請成為服務平台之企業月結會員。
            </Text>
          </div>
        </Row>
        <Row margin="0 0 30px 0">
          <Button
            style={{borderRadius: 5}}
            onClick={() =>
              app.actions.setModal({
                content: <MonthlyNotice />,
                ...monthlyNoticeMeta,
              })
            }>
            月結會員交易須知
          </Button>
          <div style={{flex: 1}} />
          <Button disabled={!editable} onClick={submit}>
            {editable ? '送出月結會員申請' : '已送出月結會員申請'}
          </Button>
        </Row>
      </FormExpander>
    </div>
  );
}

const Description = styled.div`
  & > h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  & > .content {
    margin-bottom: 20px;
    & > p {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
`;
