import React, {useContext, useState, Fragment} from 'react';
import {Context} from '../AppContext';
import {
  FormExpander,
  Row,
  Label,
  Input,
  Button,
  ErrorHint,
  Text,
} from '../Widgets';
import {message} from 'antd';
import {ErrProfileFormat} from '../errors';
import Image from '../Components/Image';
const config = require('../data.json');

export default function ChangePasswordForm(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [checkNewPassword, setCheckNewPassword] = useState('');
  const [validate, setValidate] = useState(false);

  const submit = async () => {
    setValidate(true);
    try {
      if (newPassword !== checkNewPassword) {
        throw new ErrProfileFormat('確認密碼與新密碼不相同！');
      }
      await app.actions.changePassword({
        username: profile.user.username,
        password,
        new_password: newPassword,
      });
      message.success('修改密碼成功！');
      setPassword('');
      setNewPassword('');
      setCheckNewPassword('');
      setValidate(false);
    } catch (err) {
      console.log(err);
      if (err instanceof ErrProfileFormat) {
        message.error(err.message);
      } else if (err.error === 'invalid_code') {
        message.error('舊密碼錯誤');
      } else {
        message.error('修改時發生錯誤');
      }
    }
  };

  return (
    <div>
      <FormExpander title="重設登入密碼" closable={false}>
        {profile.social_type !== 'default' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 40,
            }}>
            <Image src="/images/password-lock.svg" alt="password lock" />
            <Text color={config.colors.error} style={{marginTop: 20}}>
              此登入帳號為社群帳號無修改密碼功能
            </Text>
          </div>
        ) : (
          <Fragment>
            <Row>
              <Label>舊的密碼</Label>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Row>
            <Row>
              <Label>新的密碼</Label>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Row>
            <Row>
              <Label>密碼確認</Label>
              <Input
                type="password"
                value={checkNewPassword}
                onChange={(e) => setCheckNewPassword(e.target.value)}
              />
              {validate && checkNewPassword !== newPassword && (
                <ErrorHint>確認密碼與新密碼不相同</ErrorHint>
              )}
            </Row>
            <Row margin="0 0 30px 0">
              <div style={{flex: 1}} />
              <Button onClick={submit}>修改登入密碼</Button>
            </Row>
          </Fragment>
        )}
      </FormExpander>
    </div>
  );
}
