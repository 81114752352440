import React, {useContext} from 'react';
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Layout from '../../Components/ProfileLayout';
import ProfileForm from '../../Forms/ProfileForm';
import ChangePwForm from '../../Forms/ChangePwForm';
import RequestEntForm from '../../Forms/RequestEntForm';
import RequestMonthlyForm from '../../Forms/RequestMonthlyForm';
const qs = require('query-string');

export default function Profile(props) {
  const app = useContext(Context);
  const pathname = props.location.pathname;
  const {tabName} = qs.parse(props.location.search);

  if (!app.state.profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <Layout pathname={pathname} tabName={tabName}>
        {({tab}) => {
          switch (tab.name) {
            case 'myProfile':
              return <ProfileForm />;

            case 'changePw':
              return <ChangePwForm />;

            case 'reqEnt':
              return <RequestEntForm />;

            case 'reqMonthly':
              return <RequestMonthlyForm />;
            default:
              return null;
          }
        }}
      </Layout>
    </ContentWrapper>
  );
}
