import React from 'react';
import {Heading, Row, Button} from '../Widgets';
import {Context} from '../AppContext';
const config = require('../data.json');
const htmls = require('../content-html.json');

export default function Terms() {
  const app = React.useContext(Context);
  return (
    <div>
      <div
        style={{textAlign: 'left', padding: 20}}
        dangerouslySetInnerHTML={{__html: htmls.monthly_notice}}></div>
      <Row style={{justifyContent: 'flex-end'}}>
        <Button onClick={() => app.actions.setModal()}>確定</Button>
      </Row>
    </div>
  );
}

const metadata = {
  title: <Heading align="center">月結會員交易須知</Heading>,
  width: '80%',
};

export {metadata};
