import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import {
  FormExpander,
  Row,
  Label,
  Input,
  Text,
  Button,
  ErrorHint,
} from '../Widgets';
import {message} from 'antd';
import {ErrProfileFormat} from '../errors';
import formatValidator from '../Utils/formatValidator';
import {UPGRADE_STATUS} from '../dictionary';
import AddressField from '../Components/AddressField';
const appConfig = require('../data.json');

const {isNotEmpty, isNumeric} = formatValidator;

export default function RequestEntPanel(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [data, _setData] = useState({});
  const [validate, setValidate] = useState(false);
  const [upgrade, setUpgrade] = useState();
  const editable = !(
    upgrade?.state === 'pending' || upgrade?.state === 'rejected'
  );

  function setData(obj) {
    _setData((pre) => ({...pre, ...obj}));
  }

  useEffect(() => {
    if (profile) {
      try {
        _setData(JSON.parse(profile.data));
      } catch (ex) {}
    }
  }, [profile]);

  useEffect(() => {
    (async () => {
      try {
        let upgrade = await app.actions.getUpgrade();
        if (upgrade) {
          setUpgrade(upgrade);
          let data = JSON.parse(upgrade.data);
          setData(data || {});
        }
      } catch (err) {}
    })();
  }, [profile]);

  const valid = () => {
    setValidate(true);
    if (
      !isNotEmpty(data.ent_name) ||
      !isNotEmpty(data.gui_number) ||
      !isNotEmpty(data.ent_tel) ||
      !isNotEmpty(data.ent_address) ||
      !isNotEmpty(data.ent_city) ||
      !isNotEmpty(data.ent_district)
    ) {
      throw new ErrProfileFormat('欄位不能為空');
    } else if (!isNumeric(data.gui_number) || !isNumeric(data.ent_tel)) {
      throw new ErrProfileFormat('格式錯誤');
    }
  };

  const submit = async () => {
    try {
      valid();

      await app.actions.reqUpgradeUserType({
        data: JSON.stringify(data),
      });
      await app.actions.getProfile();
      message.success('已送出申請');
    } catch (err) {
      console.warn(err);
      if (err instanceof ErrProfileFormat) {
        message.error(err.message);
      } else {
        message.error('發生錯誤');
      }
    }
  };

  // const submit = async () => {
  //   try {
  //     valid();

  //     let req_data = {};
  //     if (profile.user_type === 'vip') {
  //       req_data = {req_ent_vip: true};
  //     } else if (profile.user_type === 'normal') {
  //       req_data = {req_ent: true};
  //     }
  //     await app.actions.editProfile({
  //       ...req_data,
  //       data: JSON.stringify(data),
  //     });
  //     await app.actions.getProfile();
  //     message.success('修改成功');
  //   } catch (err) {
  //     console.warn(err);
  //     if (err instanceof ErrProfileFormat) {
  //       message.error(err.message);
  //     } else {
  //       message.error('修改錯誤');
  //     }
  //   }
  // };

  return (
    <div>
      <FormExpander title="企業會員申請表" closable={false}>
        {upgrade && (
          <Row>
            <Label flex="0 0 70px">申請狀態</Label>
            <Text
              size="sm"
              color={
                {
                  rejected: appConfig.colors.error,
                  accepted: appConfig.colors.success,
                }[upgrade.state]
              }>
              {UPGRADE_STATUS[upgrade.state]}
            </Text>
          </Row>
        )}
        {upgrade?.state === 'rejected' && (
          <Row>
            <Label flex="0 0 70px">拒絕原因</Label>
            <Text size="sm" color={appConfig.colors.error}>
              {upgrade.reason}
            </Text>
          </Row>
        )}
        <Row>
          <Label flex="0 0 70px">公司名稱*</Label>
          <Input
            disabled={!editable}
            placeholder="公司名稱"
            value={data.ent_name || ''}
            onChange={(e) => setData({ent_name: e.target.value})}
          />
          {validate && !isNotEmpty(data.ent_name) && (
            <ErrorHint>公司名稱不能為空</ErrorHint>
          )}
        </Row>
        <Row>
          <Label flex="0 0 70px">統一編號*</Label>
          <Input
            disabled={!editable}
            value={data.gui_number || ''}
            placeholder="統一編號"
            onChange={(e) => setData({gui_number: e.target.value})}
          />
          {validate && !isNotEmpty(data.gui_number) && (
            <ErrorHint>統一編號不能為空</ErrorHint>
          )}
        </Row>
        <Row>
          <Label flex="0 0 70px">公司電話*</Label>
          <Input
            disabled={!editable}
            value={data.ent_tel || ''}
            placeholder="公司電話"
            onChange={(e) => setData({ent_tel: e.target.value})}
          />
          <Input
            disabled={!editable}
            placeholder="分機"
            type="short"
            value={data.tel_ext || ''}
            onChange={(e) => setData({tel_ext: e.target.value})}
          />

          {validate && !isNotEmpty(data.ent_tel) ? (
            <ErrorHint>公司電話不能為空</ErrorHint>
          ) : validate && !isNumeric(data.ent_tel) ? (
            <ErrorHint>請確認公司電話格式</ErrorHint>
          ) : null}
        </Row>
        <Row>
          <Label flex="0 0 70px">公司地址*</Label>
          <AddressField
            disabled={!editable}
            zip_code={data.ent_zip_code}
            city={data.ent_city}
            district={data.ent_district}
            address={data.ent_address}
            onChange={(obj) =>
              setData({
                ...(obj.zip_code !== undefined && {ent_zip_code: obj.zip_code}),
                ...(obj.city !== undefined && {ent_city: obj.city}),
                ...(obj.district !== undefined && {ent_district: obj.district}),
                ...(obj.address !== undefined && {ent_address: obj.address}),
              })
            }
          />
          {/* <Input
            disabled={!editable}
            placeholder="公司地址"
            type="long"
            value={data.ent_address || ''}
            onChange={(e) => setData({ent_address: e.target.value})}
          /> */}
          {validate && !isNotEmpty(data.ent_address) && (
            <ErrorHint>公司地址不能為空</ErrorHint>
          )}
          {validate &&
            (!isNotEmpty(data.ent_city) || !isNotEmpty(data.ent_district)) && (
              <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
            )}
        </Row>

        <Row margin="0 0 30px 0">
          <div style={{flex: 1}} />
          {editable ? (
            <Button onClick={submit}>送出企業會員申請</Button>
          ) : (
            <Button disabled>已送出企業會員申請</Button>
          )}
        </Row>
      </FormExpander>
    </div>
  );
}
